import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          margin: '0',
          textTransform: 'none',
          fontSize: '16px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...{
            transition: theme.transitions.create(['transform'], {
              duration: theme.transitions.duration.standard
            }),
            '&:hover': {
              transform: `scale(1.05)`
            },
            borderRadius: 30
          }
        })
      }
    }
  },
  palette: {
    customColors: {
      gray: {
        500: '#757575',
        700: '#56615E'
      },
      green: {
        400: '#34A853'
      },
      black: {
        900: '#262C2B'
      },
      blue: {
        100: '#1877f2'
      }
    }
  }
});
