import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect, useDispatch } from 'react-redux';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as actionFirestore } from 'store/reducers/news/actions/actionFirestore';
import { routerPath } from 'router/Routerlist';
import DashboardHeaderMenu from 'components/DashboardHeader/Top';
import { CHANGE_ATTRIBUTE as CHANGE_ATTRIBUTE_NEWS } from 'store/reducers/news/news.constant';

import PlaceholderImage from 'assets/images/placeholder.jpg';

import IconDot from 'assets/dots.svg';
import IconClose from 'assets/close-icon.svg';
import IconSend from 'assets/bxs-send.svg';
import IconSlash from 'assets/slash.svg';
import IconEdit from 'assets/pen.svg';
import IconCheck from 'assets/checkUnfill.svg';
import IconTrash from 'assets/trash.svg';
import CloseIcon from 'assets/close-icon.svg';
import delAccount from 'assets/account-delete.svg';
import IconPlane from 'assets/paperplan-line.svg';
import moment from 'moment';
import { CircularProgress, Stack, Typography } from '@mui/material';
import FooterDashboard from 'components/FooterLink/Dashboard';
import { db, ref, off } from 'utils/firebase';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  useGetApplicantsProfileQuery,
  useGetDetailJobsQuery,
  useGetDetailPerProjectQuery,
  useGetDetailSpecificJobsQuery,
  usePatchProfilewithOutFromUpdateMutation,
  usePostSubmitReportMutation,
  useViewApplicantsProfileMutation
} from 'store/slices/apiSlice';
import {
  onChildAdded,
  push as pushFirebaseChat,
  set as setFirebaseChat,
  remove as removeChat,
  update as updateFirebaseChat,
  child as childFirebaseChat,
  increment as incrementFirebaseDatabase,
  get,
  onChildRemoved
} from 'firebase/database';
import { v4 as uuid } from 'uuid';
import { JOB_SEEKER } from 'constant';
import { HandleGetProfileRequest } from 'api/callsHandler';
import { getLastMessageTime, saveLastMessageTime } from 'utils/helper';

export const refDbRoomName = 'chatrooms';
const refDbRoomDetailName = 'chatroomdetail';

const RoomItem = ({ d, i, activeRoom, chooseRoom }) => {
  const {
    data: dataProfile,
    isLoading: isLoadProfile,
    isSuccess: isSuccessGetProfile
  } = useGetApplicantsProfileQuery(d?.toId);

  const [isCameFromSearchPage, setIsCameFromSearchPage] = useState(true);

  const { data: jobDetailDataSearchPage, error: jobDetailErrorSearchPage } = useGetDetailJobsQuery({
    id: d.referenceId,
    cancelCall: [undefined, false].includes(isCameFromSearchPage)
  });

  const { data: jobDetailDataManagePage, error: jobDetailErrorManagePage } =
    useGetDetailSpecificJobsQuery({
      id: d.referenceId,
      cancelCall: d.reference === 'Project' || [undefined, true].includes(isCameFromSearchPage)
    });

  const { data: projectDetailData } = useGetDetailPerProjectQuery({
    id: d.referenceId,
    cancelCall: d.reference === 'Job'
  });
  useEffect(() => {
    if (jobDetailErrorSearchPage && Object.keys(jobDetailErrorSearchPage).includes('status')) {
      if (jobDetailErrorSearchPage?.status === 404) {
        setIsCameFromSearchPage(false);
      } else {
        setIsCameFromSearchPage(true);
      }
    }
  }, [jobDetailErrorSearchPage]);

  const jobDetailData = useMemo(
    () => (isCameFromSearchPage ? jobDetailDataSearchPage : jobDetailDataManagePage),
    [jobDetailDataSearchPage, jobDetailDataManagePage, isCameFromSearchPage]
  );
  const userImage =
    dataProfile?.user_type === JOB_SEEKER
      ? dataProfile?.profile_image?.split('?')[0] ?? PlaceholderImage
      : dataProfile?.company?.logo?.split('?')[0] ?? PlaceholderImage;

  return (
    <div
      className={`message-item ${activeRoom == d.chatId ? 'active' : ''}`}
      onClick={() => chooseRoom(d.chatId)}
      key={i}>
      {isLoadProfile ? (
        <Stack width={'100%'} direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <div>
            <div className="message-pp">
              <img src={userImage} />
            </div>
          </div>
          <div className="pl-1">
            <div className="username" style={{ textTransform: 'capitalize' }}>
              {dataProfile?.name}
              <Typography component={'span'} fontWeight={'300'} color={'text.secondary'}>{`${
                d?.reference === 'Job'
                  ? `/${jobDetailData?.title}`
                  : d.reference === 'Project'
                  ? projectDetailData?.title
                  : ''
              }`}</Typography>
            </div>
            <div className="position">{dataProfile?.userType}</div>
          </div>
          <div
            className={`${d.hasUnRead === 0 && 'hide'}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'flex-end'
            }}>
            <div
              style={{
                backgroundColor: '#c4dd80',
                borderRadius: '20px',
                width: '20px',
                textAlign: 'center',
                color: '#000',
                fontSize: '10px',
                height: '20px',
                lineHeight: '21px'
              }}>
              {d?.hasUnRead}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const MessageHeader = ({
  activeRoom,
  rooms,
  deleteChat,
  fromDataProfile,
  blockUser,
  blockDialog,
  unblockDialog,
  reportDialog
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const room = useMemo(
    () => rooms.find((e) => e.chatId === activeRoom) ?? undefined,
    [activeRoom, rooms]
  );

  const {
    data: dataProfile,
    isLoading: isLoadProfile,
    isSuccess: isSuccessGetProfile
  } = useGetApplicantsProfileQuery(room?.toId);

  const [isCameFromSearchPage, setIsCameFromSearchPage] = useState(true);

  const { data: jobDetailDataSearchPage, error: jobDetailErrorSearchPage } = useGetDetailJobsQuery({
    id: room?.referenceId,
    cancelCall: [undefined, false].includes(isCameFromSearchPage)
  });

  const { data: jobDetailDataManagePage, error: jobDetailErrorManagePage } =
    useGetDetailSpecificJobsQuery({
      id: room?.referenceId,
      cancelCall: room?.reference === 'Project' || [undefined, true].includes(isCameFromSearchPage)
    });

  const { data: projectDetailData, currentData: projectDetailCurrentDataManagePage } =
    useGetDetailPerProjectQuery({
      id: room?.referenceId,
      cancelCall: room?.reference === 'Job'
    });

  useEffect(() => {
    if (jobDetailErrorSearchPage && Object.keys(jobDetailErrorSearchPage).includes('status')) {
      if (jobDetailErrorSearchPage?.status === 404) {
        setIsCameFromSearchPage(false);
      } else {
        setIsCameFromSearchPage(true);
      }
    }
  }, [jobDetailErrorSearchPage]);

  const jobDetailData = useMemo(
    () => (isCameFromSearchPage ? jobDetailDataSearchPage : jobDetailDataManagePage),
    [jobDetailDataSearchPage, jobDetailDataManagePage, isCameFromSearchPage]
  );

  const userImage =
    dataProfile?.user_type === JOB_SEEKER
      ? dataProfile?.profile_image?.split('?')[0] ?? PlaceholderImage
      : dataProfile?.company?.logo?.split('?')[0] ?? PlaceholderImage;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="message-header">
      <div>
        <div className="message-pp">
          <img src={userImage} />
        </div>
      </div>
      <div className="pl-1 w-100">
        <div className="username" style={{ textTransform: 'capitalize' }}>
          {dataProfile?.name}
          <Typography component={'span'} fontWeight={'300'} color={'text.secondary'}>{`${
            room?.reference === 'Job'
              ? `/${jobDetailData?.title}`
              : room?.reference === 'Project'
              ? projectDetailData?.name
              : ''
          }`}</Typography>
        </div>
        <div className="position">{dataProfile?.user_type}</div>
      </div>
      <div>
        <div className="pointer" onClick={openMenu}>
          <img src={IconDot} className="middle" />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <div className="dropdown" style={{ position: 'fixed' }}>
            <div className="dropdown-list">
              <div
                className="dropdown-item d-flex items-center"
                onClick={
                  blockUser && fromDataProfile.blocked_users?.includes(dataProfile?.id)
                    ? unblockDialog
                    : blockDialog
                }>
                <div className="ico-menu">
                  <img
                    src={
                      blockUser && fromDataProfile.blocked_users?.includes(dataProfile?.id)
                        ? IconCheck
                        : IconSlash
                    }
                    className="sub"
                  />
                </div>
                <div className="pl-1">
                  {blockUser
                    ? fromDataProfile.blocked_users?.includes(dataProfile?.id)
                      ? 'Unblock'
                      : 'Block'
                    : 'Block'}
                </div>
              </div>
              <div className="dropdown-item d-flex items-center" onClick={reportDialog}>
                <div className="ico-menu">
                  <img src={IconEdit} className="sub" />
                </div>
                <div className="pl-1">Report User</div>
              </div>
              <div className="dropdown-item d-flex items-center" onClick={deleteChat}>
                <div className="ico-menu">
                  <img src={IconTrash} className="sub" />
                </div>
                <div className="pl-1">Delete chat</div>
              </div>
              {/* <div className="dropdown-item d-flex items-center" onClick={unblockDialog}>
                <div className="ico-menu">
                  <img src={IconCheck} className="sub" />
                </div>
                <div className="pl-1">Unblock</div>
              </div> */}
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

const RoomContainer = ({ rooms, activeRoom, chooseRoom, setActiveRoom, authenticated }) => {
  const [openDialog, setOpen] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [inputError, setInputError] = useState(null);
  const [roomMessages, setRoomMessages] = useState([]);
  const [blockUser, setBlockUser] = useState(false);
  const [optionValue, setOptionValue] = useState('');

  const chatInput = useRef(null);
  const endOfMessage = useRef(null);

  const room = useMemo(
    () => rooms.find((e) => e.chatId === activeRoom) ?? undefined,
    [activeRoom, rooms]
  );

  const {
    data: dataProfile,
    isLoading: isLoadProfile,
    isSuccess: isSuccessGetProfile
  } = useGetApplicantsProfileQuery(room?.toId);

  const {
    data: fromDataProfile,
    isLoading: isLoadFromProfile,
    isSuccess: isSuccessGetFromProfile,
    refetch: refetchFromProfile
  } = useGetApplicantsProfileQuery(room?.fromId);

  const [
    patchProfileUpdate,
    {
      isLoading: isLoadingSuccessProfileUpdate,
      isSuccess: isSuccessProfileUpdate,
      isError: isErrorProfileUpdate,
      error: errorProfileUpdate,
      data: dataProfilePatchUpdate
    }
  ] = usePatchProfilewithOutFromUpdateMutation();

  const [
    postSubmitReport,
    {
      isLoading: isLoadingSuccessSubmitReport,
      isSuccess: isSuccessSubmitReport,
      isError: isErrorSubmitReport,
      error: errorSubmitReport,
      data: dataSubmitReport
    }
  ] = usePostSubmitReportMutation();

  const { data: profileData } = HandleGetProfileRequest('', authenticated?.id);

  const updateHasUnReadMyMessage = useCallback(() => {
    // /** UPDATING hasRead for opponent chat user */
    const opponent = rooms.find((e) => e.chatId === activeRoom);
    const opponentPath = `${refDbRoomName}/${opponent?.fromId}/${opponent?.fromKey}/hasUnRead`;
    const updates = {};
    updates[opponentPath] = 0;
    updateFirebaseChat(ref(db), updates);
  }, [rooms, activeRoom]);

  const updateHasUnReadMessageRoomOpponent = useCallback(() => {
    // /** UPDATING hasRead for opponent chat user */
    const opponent = rooms.find((e) => e.chatId === activeRoom);
    const opponentPath = `${refDbRoomName}/${opponent?.toId}/${opponent?.toKey}/hasUnRead`;
    const updates = {};
    updates[opponentPath] = incrementFirebaseDatabase(1);
    updateFirebaseChat(ref(db), updates);
  }, [rooms, activeRoom]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const text = chatInput.current.querySelector('input#chatbox').value;
      if (text === '') {
        setInputError('This field is required');
      } else {
        setInputError(null);

        const currentTime = moment();
        const lastMessageTime = moment(getLastMessageTime());
        const doesTimeExist = getLastMessageTime();

        const data = {
          email: dataProfile?.email,
          message: `Hello,\n\n\nThis email is to notify you that you have received a new message through the Workium network.\n\n\nWarm regards,\n\nWorkium Team\n\n`,
          subject: 'New Message Received'
        };

        if (dataProfile?.messages_notification) {
          if (
            !doesTimeExist ||
            !lastMessageTime.isValid() ||
            currentTime.diff(lastMessageTime, 'minutes') > 3 ||
            true
          ) {
            saveLastMessageTime(currentTime);
            postSubmitReport(data);
          } else {
            console.log('A message was sent less than 3 minute ago.');
          }
        }

        const query = ref(db, `${refDbRoomDetailName}/${activeRoom}`);
        const newChatRef = pushFirebaseChat(query);
        const newChatValue = {
          from: profileData?.data?.name,
          fromId: profileData?.data?.id,
          userType: profileData?.data?.user_type,
          chatId: activeRoom,
          messageId: uuid(),
          createdAt: moment().format(),
          text,
          chatKey: newChatRef.key
        };
        const pushChat = await setFirebaseChat(newChatRef, newChatValue);
        chatInput.current.querySelector('input#chatbox').value = '';

        updateHasUnReadMessageRoomOpponent();
        updateHasUnReadMyMessage();
      }
    },
    [
      activeRoom,
      updateHasUnReadMyMessage,
      updateHasUnReadMessageRoomOpponent,
      authenticated,
      profileData
    ]
  );

  const getTimestamps = (time) => {
    //if time is today
    if (moment(time).isSame(moment(), 'day')) {
      return moment(time).format('LT');
    }
    // if time is yesterday
    else if (moment(time).isSame(moment().subtract(1, 'days'), 'day')) {
      return moment(time).fromNow(true);
    }

    // if time is within this year
    else if (moment(time).isSame(moment(), 'year')) {
      moment(time).format('MMM Do HH:mm');
    } else {
      moment(time).format('llll');
    }
  };

  const deleteChat = useCallback(async () => {
    const room = rooms.find((d) => d.chatId === activeRoom);

    if (room && db && activeRoom) {
      let query = ref(db, `${refDbRoomName}/${room?.fromId}/${room?.fromKey}`);
      await removeChat(query);

      query = ref(db, `${refDbRoomName}/${room?.toId}/${room?.toKey}`);
      await removeChat(query);

      const dbRef = ref(db);
      get(childFirebaseChat(dbRef, `${refDbRoomDetailName}/${activeRoom}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            query = ref(db, `${refDbRoomDetailName}/${activeRoom}`);

            removeChat(`${refDbRoomDetailName}/${activeRoom}`);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // setRooms((prev) => prev.filter((room) => room.chatId !== activeRoom));
          setOpen(false);
          setActiveRoom(0);

          // navigate('/messages');
        });
    }
  }, [rooms, activeRoom, setActiveRoom]);

  const blockDialog = () => {
    setOpenBlock(!openBlock);
  };

  useEffect(() => {
    if (isSuccessProfileUpdate) {
      refetchFromProfile();
    }
  }, [dataProfilePatchUpdate]);

  const unblockDialog = () => {
    if (fromDataProfile?.blocked_users?.includes(room?.toId)) {
      let payloadProfile = {
        profileId: fromDataProfile?.id,
        body: {
          blocked_users: fromDataProfile?.blocked_users.filter((e) => e !== parseInt(room?.toId))
        }
      };
      patchProfileUpdate(payloadProfile);
    }
  };

  const deleteClose = () => {
    setOpen(!openDialog);
  };

  const onAddRoomMessages = useCallback(
    (data) => {
      let roomDetails = [];
      Object.values(data).map((message) => {
        roomDetails.push(message);
      });

      setRoomMessages([...roomDetails]);
    },
    [setRoomMessages]
  );

  const getChatRoomDetail = useCallback(() => {
    const dbRef = ref(db);
    get(childFirebaseChat(dbRef, `${refDbRoomDetailName}/${activeRoom}`))
      .then((snapshot) => {
        const data = snapshot.val();

        if (snapshot.exists()) {
          onAddRoomMessages(data);
        } else {
          if (!roomMessages || roomMessages.length > 0) {
            setRoomMessages([]);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [onAddRoomMessages, setRoomMessages, activeRoom, roomMessages]);

  useEffect(() => {
    if (activeRoom) {
      getChatRoomDetail();
    }
  }, [activeRoom]);
  useEffect(() => {
    if (fromDataProfile?.blocked_users?.includes(room?.toId)) {
      setBlockUser(true);
    } else {
      setBlockUser(false);
    }
  }, [fromDataProfile]);

  const onTriggerChildMessagesAdded = useCallback(
    (data) => {
      // debugger;
      if (roomMessages && !roomMessages.map((e) => e.messageId).includes(data.val().messageId)) {
        setRoomMessages((roomMessages) => [
          ...roomMessages.filter((e) => e.chatId === data.val()?.chatId),
          data.val()
        ]);
      }
    },
    [roomMessages, setRoomMessages]
  );

  useEffect(() => {
    let query;

    if (activeRoom && activeRoom !== 0) {
      query = ref(db, `${refDbRoomDetailName}/${activeRoom}`);

      onChildAdded(query, (data) => {
        onTriggerChildMessagesAdded(data);
      });
    }

    return () => {
      return query ? off(query, 'child_added') : () => undefined;
    };
  }, [activeRoom, onTriggerChildMessagesAdded]);

  const blockUserHandler = () => {
    let payloadProfile = {
      profileId: fromDataProfile?.id,
      body: {
        blocked_users: [...fromDataProfile?.blocked_users, parseInt(room?.toId)]
      }
    };
    patchProfileUpdate(payloadProfile);
    setOpenBlock(false);
  };

  const reportDialogToggle = () => {
    setOpenReport(!openReport);
  };

  const reportDialogSubmit = () => {
    let data = {
      email: 'team@workium.com',
      message: `Hello,\n\n${fromDataProfile?.name || fromDataProfile?.company?.name} (User ID: ${
        fromDataProfile?.id
      }/Email: ${fromDataProfile?.email}) has submitted a report concerning ${
        dataProfile?.name
      } (User ID: ${dataProfile?.id}/Email: ${
        dataProfile?.email
      }). Kindly review this matter at your earliest convenience.\nReason: ${optionValue}\n\nThankyou.`,
      subject: 'Review Request: A new report request was made'
    };

    postSubmitReport(data);

    reportDialogToggle();
  };

  return (
    <div className="content-inner">
      <div className="message-container">
        <div className="message" style={{ flex: '0 0 100%', maxWidth: '100%' }}>
          <div className="message-list">
            {!!rooms.length &&
              rooms.map((d, i) => (
                <RoomItem d={d} i={i} activeRoom={activeRoom} chooseRoom={chooseRoom} />
              ))}
          </div>
          {!!activeRoom && (
            <div className="message-content">
              <MessageHeader
                activeRoom={activeRoom}
                rooms={rooms}
                deleteChat={() => setOpen(true)}
                blockDialog={blockDialog}
                blockUser={blockUser}
                authenticated={authenticated}
                fromDataProfile={fromDataProfile}
                reportDialog={reportDialogToggle}
                unblockDialog={unblockDialog}
              />
              <div className={`message-body ${!!attachment ? 'has-attachment' : ''}`}>
                <div className="message-date hide">
                  <span className="">25 Aug 2020</span>
                </div>
                {!!activeRoom && !!roomMessages.length ? (
                  roomMessages?.map((d, i) => (
                    <div
                      className={
                        d.fromId === authenticated?.id ? 'message-outgoing' : 'message-incoming'
                      }
                      key={i}>
                      {!!d?.attachment && (
                        <div className="attachment">
                          <div className="topic">{d.attachment}</div>
                          <div className="title">{d.attachment_title}</div>
                        </div>
                      )}
                      <div className="message-inner">
                        <p>{d.text}</p>
                        <p className="message-time">{getTimestamps(d?.createdAt)}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="message-empty">
                    <img src={IconPlane} />

                    <p>
                      This is the very beginning of your direct message history with <br />
                      <span className="underline">{dataProfile?.name}</span>
                    </p>
                  </div>
                )}
                <div ref={endOfMessage}></div>
              </div>
              <div className="message-footer">
                {!!attachment && (
                  <div className="message-attachment">
                    {!!attachment.image && <img src={attachment?.image} />}
                    <div className="content">
                      <div className="topic">{attachment?.topic}</div>
                      <div className="title">{attachment?.title}</div>
                    </div>
                    <button className="btn btn-sm" onClick={() => setAttachment(null)}>
                      <img src={IconClose} />
                    </button>
                  </div>
                )}
                <div className="message-input">
                  {blockUser == true ? (
                    <div className="w-100" style={{ height: '4em', justifyItems: 'center' }}>
                      {dataProfile.blocked_users?.includes(fromDataProfile?.id) ? (
                        <>
                          You can't send message to this user because you are blocked by this user
                        </>
                      ) : (
                        <>
                          You have blocked this user, if you wanna continue chating please unblock
                          this user.
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {/* <div>
                        <button className='btn btn-inp--21'>
                            <img src={IconEmo} className='middle' />
                        </button>
                    </div> */}
                      <div className="w-100">
                        <form ref={chatInput} onSubmit={handleSubmit}>
                          <input placeholder="Write a message (Enter to send)" id="chatbox" />
                          {inputError && (
                            <Typography variant="subtitle2" fontSize={'12px'} color={'error'}>
                              {inputError}
                            </Typography>
                          )}
                        </form>
                      </div>
                      {/* <div>
                        <button className='btn btn-inp--21'>
                            <img src={IconAtt} className='middle' />
                        </button>
                    </div> */}
                      <div>
                        <button className="btn btn-inp--21" onClick={(e) => handleSubmit(e)}>
                          <img src={IconSend} className="middle" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="message-close mui--text-right hide">
          <button className="btn mt-2 mr-2">
            <img src={IconClose} className="filter-white" />
          </button>
        </div>
      </div>
      <Dialog open={openDialog} onClose={deleteClose} aria-labelledby="responsive-dialog-title">
        <div className="modal modal-sm">
          <div className="modal-header">
            <h3>Delete Chat History?</h3>
            <div className="modal-close" onClick={deleteClose}>
              <img src={CloseIcon} />
            </div>
          </div>
          <div className="modal-content mui--text-center">
            <img src={delAccount} className="mt-3" />
            <p className="allert-mm mt-3 mb-3">
              Are you sure you want to delete chat with{' '}
              {rooms.find((e) => e.chatId === activeRoom)?.to}?
            </p>
            <div className="mui--text-center">
              <button className="btn btn-outline-dark mr-1" onClick={deleteClose}>
                Cancel
              </button>
              <button className="btn btn-dark mr-1" onClick={deleteChat}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={openBlock} onClose={blockDialog} aria-labelledby="responsive-dialog-title">
        <div className="modal modal-sm">
          <div className="modal-header">
            <h3>Block Chat User?</h3>
            <div className="modal-close" onClick={blockDialog}>
              <img src={CloseIcon} />
            </div>
          </div>
          <div className="modal-content mui--text-center">
            <img src={delAccount} className="mt-3" />
            <p className="allert-mm mt-3 mb-3">
              Are you sure you want to Block chat with{' '}
              {rooms.find((e) => e.chatId === activeRoom)?.to}?
            </p>
            <div className="mui--text-center">
              <button className="btn btn-outline-dark mr-1" onClick={blockDialog}>
                Cancel
              </button>
              <button className="btn btn-dark mr-1" onClick={blockUserHandler}>
                Block
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openReport}
        onClose={reportDialogToggle}
        aria-labelledby="responsive-dialog-title">
        <div className="modal modal-sm">
          <div className="modal-header">
            <h3>What's the problem with Romuald Anderson?</h3>
            <div className="modal-close" onClick={reportDialogToggle}>
              <img src={CloseIcon} />
            </div>
          </div>
          <div className="modal-content">
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setOptionValue('Sending me a spam')}
                    name="checkedB"
                    color="success"
                  />
                }
                label="Sending me a spam"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setOptionValue('Running a scam')}
                    name="checkedB"
                    color="success"
                  />
                }
                label="Running a scam"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setOptionValue('Has inappropriate content')}
                    name="checkedB"
                    color="success"
                  />
                }
                label="Has inappropriate content"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setOptionValue('Violated the terms of the contract')}
                    name="checkedB"
                    color="success"
                  />
                }
                label="Violated the terms of the contract"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setOptionValue('Rude or abusive')}
                    name="checkedB"
                    color="success"
                  />
                }
                label="Rude or abusive"
              />
            </div>
            <div className="mui-row mt-2">
              <div className="mui-col-xs-6">
                <button onClick={reportDialogToggle} className="btn btn-outline-dark btn-block">
                  Cancel
                </button>
              </div>
              <div className="mui-col-xs-6">
                <button onClick={reportDialogSubmit} className="btn btn-dark btn-block">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const Messages = (props) => {
  const [isLoading, setLoading] = useState(false);

  const [activeRoom, setActiveRoom] = useState(0);

  const [isDeletingChat, setDeletingChat] = useState(false);
  const [isAllOff, setAllOff] = useState(undefined);

  const [isCameFromSearchPage, setIsCameFromSearchPage] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    auth: { authenticated },
    news: { messages }
  } = props;

  const { job_id, profile_id, project_id } = useParams();

  const [rooms, setRooms] = useState([]);

  const [isInitialChatLoading, setInitialChatLoading] = useState(true);

  const [
    viewApplicantsProfile,
    { data: dataProfile, isLoading: isLoadProfile, isSuccess: isSuccessGetProfile }
  ] = useViewApplicantsProfileMutation(profile_id);

  const onAddRoom = useCallback(
    (data) => {
      // if (!isDeletingChat) {
      let roomList = [];
      Object.values(data).map((room) => {
        roomList.push(room);
      });
      setRooms([...roomList]);
      // }
    },
    [setRooms]
  );

  const chooseRoom = useCallback(
    async (id) => {
      const findRoom = rooms.find((d) => d.chatId === id);
      let urlHistory = routerPath.messages.chatRoomDetail;
      if (findRoom.reference === 'Project') {
        urlHistory = routerPath.messages.chatRoomProjectDetail.replace(
          ':project_id',
          Number(findRoom.referenceId)
        );
      } else if (findRoom.reference === 'Job') {
        urlHistory = urlHistory.replace(':job_id', Number(findRoom.referenceId));
      } else {
        urlHistory = routerPath.messages.chatRoomPrivate;
      }

      urlHistory = urlHistory.replace(':profile_id', Number(findRoom?.toId));
      navigate(urlHistory);
      setActiveRoom(id);
    },
    [rooms, navigate]
  );

  const onAddNewChatRoomUser = useCallback(async () => {
    if (authenticated?.id && profile_id) {
      const query = ref(db, `${refDbRoomName}/${authenticated?.id}`);
      try {
        const newChatRef = pushFirebaseChat(query);
        const chatId = uuid();
        const opponentsRoomsQuery = ref(db, `${refDbRoomName}/${profile_id}`);
        const opponentRef = pushFirebaseChat(opponentsRoomsQuery);

        let newRoomValue = {
          to: dataProfile?.name || profile_id, // should be changed to profile_id name
          toId: Number(profile_id), // should be changed to profile_id based on params url
          toKey: opponentRef?.key,
          toImage: dataProfile?.profile_image?.split('?')?.[0] ?? 'undefined',
          userType: dataProfile?.user_type || 'Unknown',
          from: authenticated?.name,
          fromId: authenticated?.id,
          fromKey: newChatRef?.key,
          reference: job_id ? 'Job' : project_id ? 'Project' : 'Private',
          referenceId: job_id ? job_id : project_id ? project_id : 'me',
          chatId: chatId,
          text: '-',
          hasUnRead: 0
        };

        await setFirebaseChat(newChatRef, newRoomValue);
        await setFirebaseChat(opponentRef, {
          ...newRoomValue,
          to: authenticated?.name,
          toId: authenticated?.id,
          toKey: newRoomValue.fromKey,
          userType: authenticated?.user_type || 'Unknown',
          toImage: authenticated?.profile_image?.split('?')?.[0] ?? 'undefined',
          fromKey: newRoomValue.toKey,
          from: dataProfile?.name || profile_id,
          fromId: Number(profile_id)
        });
        setActiveRoom(chatId);
        // setInitialChatLoading(false);
      } catch (error) {
        // setInitialChatLoading(false);
        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'alertSeverity',
            value: 'error'
          }
        });

        dispatch({
          type: CHANGE_ATTRIBUTE_NEWS,
          payload: {
            key: 'errors',
            value: ['Failed to add new chat room']
          }
        });
      }
    }
  }, [
    profile_id,
    dataProfile,
    authenticated,
    setActiveRoom,
    // setInitialChatLoading,
    // isDeletingChat,
    job_id,
    project_id,
    dispatch
  ]);

  const getChatRoomUser = useCallback(() => {
    const dbRef = ref(db);
    get(childFirebaseChat(dbRef, `${refDbRoomName}/${authenticated?.id}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          onAddRoom(data);
        } else {
          if (job_id || project_id) {
            onAddNewChatRoomUser();
          }
        }
        setInitialChatLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });

    // return onValue(
    //   query,
    //   (snapshot) => {
    //     const data = snapshot.val();
    //     if (snapshot.exists()) {
    //       const currentRoom = `${job_id ? 'Job' : project_id ? 'Project' : 'Private'}:${
    //         Number(job_id || project_id) || 'me'
    //       }-to:${profile_id}`;
    //       const mapChatExists = Object.keys(data)
    //         .map((e) => data[e])
    //         .map((e) => `${e.reference}:${e.referenceId}-to:${Number(e.toId)}`);

    //       const newUserChatExists = mapChatExists.includes(currentRoom);
    //       console.log({
    //         newUserChatExists,
    //         currentRoom,
    //         mapChatExists
    //       });
    //       if (!newUserChatExists && profile_id) {
    //         // need to wait untill get other profile api finished
    //         onAddNewChatRoomUser(query);
    //       } else {
    //         onAddRoom(data);
    //       }
    //     } else {
    //       if (profile_id) {
    //         console.log({
    //           profile_id
    //         });
    //         onAddNewChatRoomUser(query);
    //       }
    //     }
    //     setInitialChatLoading(false);
    //   },
    //   {
    //     onlyOnce: true
    //   }
    // );
  }, [
    setInitialChatLoading,
    onAddNewChatRoomUser,
    onAddRoom,
    job_id,
    project_id,
    authenticated?.id
  ]);

  const onTriggerChildAdded = useCallback(
    (data) => {
      console.log({
        triggerChatAdded: data.val()
      });

      setRooms((rooms) => {
        if (rooms && !rooms.map((e) => e.chatId).includes(data.val().chatId)) {
          return [...rooms, data.val()];
        } else {
          return rooms;
        }
      });
    },
    [setRooms]
  );

  const onTriggerChildRemoved = useCallback(
    (data) => {
      console.log({
        triggerChatRemoved: data.val()
      });

      if (activeRoom === data.val().chatId) {
        setActiveRoom(0);
        navigate('/messages');
      }
      setRooms((prev) => prev.filter((room) => room.chatId !== data.val().chatId));
    },
    [setRooms, activeRoom, navigate]
  );

  useEffect(() => {
    getChatRoomUser();
  }, [getChatRoomUser]);

  useEffect(() => {
    if (!isInitialChatLoading) {
      const room = rooms.find((d) => d.toId === Number(profile_id));
      if (room) {
        setActiveRoom(room.chatId);
        // } else {
        //   onAddNewChatRoomUser();
      } else {
        // setActiveRoom(0);
        // navigate('/messages');
        onAddNewChatRoomUser();
      }
    }
  }, [profile_id, rooms, isInitialChatLoading, setActiveRoom, navigate]);

  useEffect(() => {
    const query = ref(db, `${refDbRoomName}/${authenticated?.id}`);

    onChildAdded(query, (data) => {
      onTriggerChildAdded(data);
    });

    return () => off(query, 'child_added');
  }, [authenticated?.id, onTriggerChildAdded]);

  useEffect(() => {
    const query = ref(db, `${refDbRoomName}/${authenticated?.id}`);

    onChildRemoved(query, (data) => {
      onTriggerChildRemoved(data);
    });

    return () => off(query, 'child_removed');
  }, [authenticated?.id, onTriggerChildRemoved]);
  console.log(rooms, 'room');

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <DashboardHeaderMenu title="Messages" />
            <RoomContainer
              rooms={rooms}
              activeRoom={activeRoom}
              chooseRoom={chooseRoom}
              setActiveRoom={setActiveRoom}
              authenticated={authenticated}
            />
            <FooterDashboard />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news,
    fb: state.reduxFirestore
  };
};
const mapDispatchToProps = { ...authAction, ...actionFirestore };
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
