import React from 'react';
import { ReactComponent as HeaderLogo } from 'assets/logo_second_version 1.svg';
import { routerPath } from 'router/Routerlist'
import { useNavigate } from 'react-router-dom';


const HomepageLogo = (props) => {
  const navigate = useNavigate()
  return (
    <a className="header-logo cursor-pointer" onClick={() => navigate(routerPath.homepage.root)}><HeaderLogo /></a>
  )
}

export default HomepageLogo
