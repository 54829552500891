import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Popover from '@mui/material/Popover';
import Slider from '@mui/material/Slider';
import ProjectCard from 'components/ProjectCard';
import SidebarMenu from 'components/SidebarMenu';
import { COUNTRY_ID, JOB_SEEKER, RECRUITER } from 'constant';
import { useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';

import cityData from 'constant/city.json';
import stateData from 'constant/state.json';

import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'router/Routerlist';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import {
  api,
  useAddFavoriteProjectMutation,
  useDeleteFavoriteProjectMutation,
  useGetFavouriteProjWithMutateMutation,
  useGetFavouriteProjectsQuery,
  useGetProjectCategoriesQuery,
  usePostQuickApplyProjectMutation,
  usePostSubmitReportMutation,
  useViewAllProjectsMutation
} from 'store/slices/apiSlice';
import { calculateDistance } from 'utils/helper';

const defaultFilter = {
  limit: 8,
  offset: 0
};

const SearchProject = ({ auth }) => {
  const dispatch = useDispatch();
  const userData = auth?.authenticated;
  const [openDialog, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [mils, setMils] = useState(0);
  const [nationWide, setNationWide] = useState(false);
  const { authenticated } = auth;
  const { data: projectCategories } = useGetProjectCategoriesQuery();
  const [selectedTrade, setSelectedTrade] = useState([]);

  const [postSubmitReport] = usePostSubmitReportMutation();

  const [filterLocation, setFilterLocation] = useState({
    city: '',
    state: ''
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (selectedTrade?.length || filterLocation?.state?.id || filterLocation?.city?.id) {
      viewAllProjects({
        ...filter,
        // ...f,
        is_paid: true,
        trade: '',
        state: '',
        city: ''
      });
      setFilterLocation('');
      setSelectedTrade('');
    }

    setAnchorEl(false);
  };

  const handleCloseModalItself = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [filter, setFilter] = useState(defaultFilter);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    userDecisionTimeout: 5000
  });

  const [
    viewAllProjects,
    { data: resultData, isLoading, isFetching: isFetchingAllProjects, refetch: refetchProjects }
  ] = useViewAllProjectsMutation();

  const [
    postQuickApplyJob,
    {
      isLoading: loadingApplyJob,
      isError: isErrorApplyJob,
      error: errorApplyJob,
      isSuccess: isSuccessQuickApply
    }
  ] = usePostQuickApplyProjectMutation();

  const { data: myFavorite, refetch: refetchFavorite } = useGetFavouriteProjectsQuery();

  const [
    addFavoriteProject,
    {
      isLoading: loadingFavouriteJob,
      isSuccess: isSuccessPostFavorite,
      isError: isErrorFavouriteJob,
      error: errorFavouriteJob
    }
  ] = useAddFavoriteProjectMutation();

  const [
    getFavouriteProjWithMutate,
    {
      data: resultDataFave,
      isLoading: loadinggetFavouriteProjWithMutate,
      isSuccess: isSuccessgetFavouriteProjWithMutate,
      isError: isErrorgetFavouriteProjWithMutate,
      error: errorgetFavouriteProjWithMutate
    }
  ] = useGetFavouriteProjWithMutateMutation();

  const [
    deleteFavoriteProject,
    {
      isLoading: isLoadingDeleteFavorite,
      error: errorDeleteingFavoriteJob,
      isSuccess: isSuccessDeletFavorite
    }
  ] = useDeleteFavoriteProjectMutation();

  useEffect(() => {
    if (isSuccessPostFavorite || isSuccessDeletFavorite) {
      getFavouriteProjWithMutate();
      // viewAllProjects({
      //   ...filter,
      //   is_paid: true
      // });
    }
  }, [isSuccessPostFavorite, isSuccessDeletFavorite]);

  useEffect(() => {
    if (isSuccessPostFavorite) {
      const message = 'Added to your favorite project!';

      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: [message]
        }
      });
      getFavouriteProjWithMutate();
      // viewAllProjects({
      //   ...filter,
      //   is_paid: true
      // });
    }
  }, [isSuccessPostFavorite]);

  useEffect(() => {
    // refetch();
    getFavouriteProjWithMutate();
    // viewAllProjects({
    //   ...filter,
    //   is_paid: true
    // });
  }, [filter]);

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value:
          isLoading ||
          isFetchingAllProjects ||
          loadingFavouriteJob ||
          isLoadingDeleteFavorite ||
          isFetchingAllProjects ||
          loadinggetFavouriteProjWithMutate
      }
    });
  }, [
    isLoading,
    loadingFavouriteJob,
    loadinggetFavouriteProjWithMutate,
    isLoadingDeleteFavorite,
    isFetchingAllProjects,
    loadinggetFavouriteProjWithMutate
  ]);

  const navigate = useNavigate();

  const onAddFavourite = (id) => {
    const payload = {
      project: id
    };

    addFavoriteProject(payload);
    // refetchFavorite();
  };

  const onDeleteFavorite = async (ids) => {
    const deleteTheJob = ids.map((e) => {
      return deleteFavoriteProject(e);
    });

    await Promise.all([...deleteTheJob]);
    // refetchFavorite();
  };

  // console.log('resultData', resultData);

  const { control } = useForm({
    resolver: yupResolver()
  });

  useEffect(() => {
    viewAllProjects({
      ...filter,
      is_paid: true
    });
  }, []);

  const onClickSearchProjects = () => {
    const params = {
      ...filter,
      is_paid: true,
      trade: selectedTrade || ''
    };

    // Add latitude, longitude, and radius if `mils` is greater than 0
    if (mils > 0 && !nationWide) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!filterLocation?.city?.id || !filterLocation?.state?.id) {
          params.latitude = coords?.latitude;
          params.longitude = coords?.longitude;
          params.radius = mils * 1.60934;
          viewAllProjects(params);
        } else if (filterLocation?.city?.id) {
          params.latitude = filterLocation?.city?.latitude;
          params.longitude = filterLocation?.city?.longitude;
          params.radius = mils * 1.60934;
          viewAllProjects(params);
        } else if (filterLocation?.state?.id) {
          params.latitude = Number(filterLocation?.state?.latitude);
          params.longitude = Number(filterLocation?.state?.longitude);
          params.radius = mils * 1.60934;
          viewAllProjects(params);
        }
      });
    } else {
      if (nationWide) {
        viewAllProjects(params);
      } else {
        viewAllProjects({
          ...params,
          state: filterLocation?.state?.id || '',
          city: filterLocation?.city?.id || ''
        });
      }
    }
  };

  const OnHandleApple = async (ele) => {
    if (ele?.user?.job_applications_notification) {
      const data = {
        email: ele?.user?.email,
        message: `Hello,\n\n\n I hope you're doing well. I'm reaching out to express my interest in the ${ele.name} that you have posted on Workium. \n\nPlease review our company’s profile and message me through Workium and include your preferred means of communication if you think we might be a good fit. \n\nI look forward to discussing your projects needs with you and how our company can help you complete another successful project. \n\nThank you for considering our application. \n\n\nWarm regards, \n\n${userData?.name}`,
        // message: `You have a new applicant for the project ${ele.name} and the applicant name is ${userData?.name}`,
        subject: `Contractor Application for ${ele.name}`
      };
      await postSubmitReport(data);
    }
    const payload = {
      name: ele.name,
      description: ele.description,
      price: ele.price,
      deadline: ele.deadline,
      is_price_negotiated: ele.is_price_negotiated,
      is_deadline_negotiated: ele.is_deadline_negotiated,
      zip_code: ele.zip_code,
      region: ele.region,
      counter: ele.counter,
      is_paid: ele.is_paid,
      term: ele.term,
      start_date: ele.start_date,
      is_reoccurring: ele.is_reoccurring,
      sub_days: ele.sub_days,
      sub_price: ele.sub_price,
      trade: ele.trade,
      city: ele.city,
      state: ele.state,
      sub_category: ele.sub_category,
      applicants: ele.applicants,
      project_id: ele.id,
      title: ele.name,
      distance: ele.distance
    };

    try {
      await postQuickApplyJob(payload).unwrap();
      viewAllProjects({
        ...filter,
        is_paid: true
      });
      refetchProjects();
      await dispatch(api.util.invalidateTags(['Post']));
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <div className="dahboard-header">
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <h1>Search Available Projects</h1>
                </Grid>
                <Grid item xs={5} style={{ textAlign: 'right' }}>
                  <button type="button" onClick={handleClick} className="btn btn-success">
                    Search Filter
                  </button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseModalItself}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'end'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}>
                    <div className="modal">
                      <div className="modal-header">
                        <h3>Set your preferences</h3>
                      </div>
                      <div className="modal-content">
                        <h4 className="text-dark mt-0">Trade</h4>
                        {/* <div className="label-outline active">Large Building Construction</div>
                        <div className="label-outline">Roofing and gutters</div>
                        <div className="label-outline">Windows & Doors</div>
                        <div className="label-outline">Foundations</div>
                        <div className="label-outline">Addition & Remodeling</div>
                        <div className="label-outline">HVAC</div> */}

                        {/* {searchTrade.map((e, idx) => (
                          <Grid item md={3} key={idx}>
                            <ControlledMultiSelect
                              key={idx}
                              id={idx}
                              control={idx}
                              errors={idx}
                              label={e.label}
                              param={'trade'}
                              marginTop={0}
                            />
                          </Grid>
                        ))} */}
                        <div
                          style={{
                            display: 'flex',
                            // justifyContent: 'space-between',
                            gap: 16,
                            flexWrap: 'wrap',
                            marginBottom: 20
                          }}>
                          {/* {projectCategories?.results.map(({ name, id }) => (
                            // <ControlledMultiSelect
                            //   key={label}
                            //   id={label}
                            //   control={control}
                            //   // errors={errors}
                            //   label={label}
                            //   param={'Searchtrades'}
                            //   marginTop={0}
                            // />

                            <button
                              key={id}
                              onClick={() => setSelectedTrade(name)}
                              className={`button_filter ${
                                selectedTrade == name ? 'button_filter_active' : ''
                              }`}>
                              {name}
                            </button>
                          ))} */}

                          {projectCategories?.results.map(({ name }) => {
                            const isActive = selectedTrade.includes(name);

                            return (
                              <button
                                key={name}
                                onClick={() =>
                                  setSelectedTrade(
                                    isActive
                                      ? selectedTrade.filter((current) => current !== name)
                                      : [...selectedTrade, name]
                                  )
                                }
                                className={`button_filter ${
                                  isActive ? 'button_filter_active' : ''
                                }`}>
                                {name}
                              </button>
                            );
                          })}
                        </div>

                        <div className="mui-row d-flex items-center">
                          <div className="mui-col-xs-6"></div>
                          <div className="mui-col-xs-6 mui--text-right text-14 text-dark"></div>
                        </div>
                        <h4 className="text-dark mt-2 mb-0">Distance</h4>
                        <Grid container spacing={2}>
                          <Grid item xs={6} className="text-14">
                            0 miles
                          </Grid>
                          <Grid item xs={6} className="text-14">
                            <Box display="flex" justifyContent="flex-end">
                              1000 miles
                            </Box>
                          </Grid>
                        </Grid>

                        <Slider
                          min={0}
                          max={1000}
                          valueLabelDisplay="on"
                          onChange={(e) => {
                            setMils(e.target.value);
                          }}
                          value={mils}
                        />

                        {/* <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <div className="form-group">
                              <label className="text-14">State</label>
                              <input
                                className="form-control form-control-sm zp--12"
                                placeholder="Enter State"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={5}>
                            <div className="form-group">
                              <label className="text-14">City</label>
                              <input
                                className="form-control form-control-sm zp--12"
                                placeholder="Enter City"
                              />
                            </div>
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={3}>
                          <Grid item md={6}>
                            <label className="text-dark">State:</label>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              value={
                                stateData?.find(
                                  (item) => item.state_id == filterLocation?.state?.id
                                )?.state_name ?? ''
                              }
                              options={stateData
                                .filter((f) => +f.country_id == COUNTRY_ID)
                                .map((e) => {
                                  return {
                                    label: e.state_name,
                                    id: e.state_id,
                                    latitude: e.latitude,
                                    longitude: e.longitude
                                  };
                                })}
                              onChange={(e, value) => {
                                console.log({
                                  value
                                });
                                setFilterLocation({
                                  ...filterLocation,
                                  state: value
                                });
                              }}
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  {option.label}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{ shrink: false }}
                                  placeholder="State"
                                  variant="outlined"
                                  className="form-control"
                                />
                              )}
                              // disabled={isJobSaved}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <label className="text-dark">City:</label>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              value={
                                cityData[2].data.find(
                                  (city) => city.city_id == filterLocation?.city?.id
                                )?.city_name ?? ''
                              }
                              options={cityData[2].data
                                .filter((f) => f.state_id == filterLocation?.state?.id)
                                .map((e) => {
                                  return {
                                    key: e.city_id,
                                    label: e.city_name,
                                    id: e.city_id,
                                    latitude: e.latitude,
                                    longitude: e.longitude
                                  };
                                })}
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  {option.label}
                                </li>
                              )}
                              onChange={(e, value) => {
                                setFilterLocation({
                                  ...filterLocation,
                                  city: value
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{ shrink: false }}
                                  variant="outlined"
                                  className="form-control"
                                  placeholder="City"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        <div className="mt-2" style={{ display: 'flex' }}>
                          <button
                            className="btn btn-primary mr-1 mb-2"
                            onClick={onClickSearchProjects}>
                            Search
                          </button>
                          <button onClick={handleClose} className="btn btn-outline-dark mr-2 mb-2">
                            Cancel
                          </button>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  p: 0,
                                  pr: 1
                                }}
                                onChange={(e) => {
                                  setNationWide(e.target.checked);
                                }}
                                checked={nationWide}
                              />
                            }
                            style={{ display: 'flex', alignItems: 'flex-start' }}
                            label="Include All Opportunities Nation Wide"
                          />
                        </div>
                      </div>
                    </div>
                  </Popover>
                </Grid>
              </Grid>
            </div>
            <div className="content-inner pt-2" style={{ marginTop: '45px' }}>
              {!!resultData &&
                resultData?.results
                  ?.filter(({ user }) => user.id !== authenticated.id)
                  // ?.filter(({ user }) => user.id !== authenticated.id)
                  ?.map(
                    (
                      {
                        name,
                        description,
                        user,
                        is_price_negotiated,
                        start_date,
                        price,
                        deadline,
                        created_at,
                        id,
                        is_paid,
                        city,
                        state,
                        trade,
                        sub_category,
                        counter,
                        is_deadline_negotiated,
                        is_reoccurring,
                        region,
                        sub_days,
                        sub_price,
                        lat,
                        long,
                        term,
                        zip_code,
                        applicants
                      },
                      idx
                    ) => {
                      // const distance = calculateDistance(
                      //   coords?.latitude,
                      //   coords?.longitude,
                      //   lat,
                      //   long
                      // );

                      return (
                        <>
                          <ProjectCard
                            name={name}
                            isFavorite={resultDataFave?.results
                              ?.map((e) => e.project.id)
                              .includes(id)}
                            onAddFavourite={() => {
                              if (resultDataFave?.results?.map((e) => e.project.id).includes(id)) {
                                const jobDeletedItems = resultDataFave.results
                                  .map((e) => {
                                    if (e.project.id === id) {
                                      return e;
                                    }
                                  })
                                  .filter((f) => f);

                                onDeleteFavorite(jobDeletedItems.map((f) => f.id));
                              } else {
                                onAddFavourite(id);
                              }
                            }}
                            onActionClick={() => {
                              OnHandleApple({
                                name,
                                description,
                                user,
                                is_price_negotiated,
                                start_date,
                                price,
                                deadline,
                                created_at,
                                id,
                                is_paid,
                                city,
                                state,
                                trade,
                                sub_category,
                                counter,
                                is_deadline_negotiated,
                                is_reoccurring,
                                region,
                                sub_days,
                                sub_price,
                                term,
                                zip_code,
                                applicants,
                                distance: 0
                              });
                            }}
                            disabled={
                              applicants?.find((x) => x.user.id == userData.id)?.id ||
                              userData?.user_type === JOB_SEEKER ||
                              userData?.user_type === RECRUITER
                                ? true
                                : false
                            }
                            // isLoading={e?.project?.id === id ? loadingApplyJob : false}
                            description={description}
                            hidePaidTag={true}
                            tags={[]}
                            address={user.city}
                            estimated_start_date={start_date}
                            price={price}
                            deadline={start_date}
                            createdAt={created_at}
                            actionTitle={
                              applicants?.find((x) => x.user.id == userData.id)?.id
                                ? 'Applied'
                                : 'Apply'
                            }
                            trade={trade}
                            key={idx}
                            isPaid={is_paid}
                            stateId={user?.state || state}
                            cityId={user?.city || city}
                            onClick={() => {
                              navigate(routerPath.projects.detail.replace(':project_id', id), {
                                state: {
                                  isFavorite: resultDataFave?.results
                                    ?.map((e) => e?.project?.id)
                                    .includes(id)
                                }
                              });
                            }}
                          />
                        </>
                      );
                    }
                  )}
              <Grid container justify="flex-end" className="mt-4">
                <Pagination
                  count={Math.ceil(resultData?.count / filter.limit)}
                  color="success"
                  variant="outlined"
                  onChange={(e, page) => {
                    setFilter({
                      ...filter,
                      offset: (page - 1) * filter.limit
                    });

                    viewAllProjects({
                      ...filter,
                      offset: (page - 1) * filter.limit,
                      is_paid: true,
                      trade: selectedTrade || '',
                      state: filterLocation?.state?.id || '',
                      city: filterLocation?.city?.id || ''
                    });
                  }}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => {
          setDialogOpen(!openDialog);
        }}>
        <DialogContent>{dialogMessage}</DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction };
export default connect(mapStateToProps, mapDispatchToProps)(SearchProject);
