import { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import ListGroup from 'components/ListGroup';
import { Box, Container, Grid } from '@mui/material';
import ProfileImage from 'assets/images/profile-img-2.jpg';
import IconMessage from 'assets/images/icon-comment.svg';
import HeaderLogo from 'components/Logo/Homepage';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetApplicantsProfileQuery } from 'store/slices/apiSlice';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { routerPath } from 'router/Routerlist';
import stateData from 'constant/state.json';
import cityData from 'constant/city.json';
import {
  GENERAL_CONTRACTOR,
  JOB_SEEKER,
  RECRUITER,
  SECOND_TIER_CONTRACTOR,
  SUB_CONTRACTOR
} from 'constant';
import { formatDescription } from 'utils/helper';

const ViewProfile = (props) => {
  const {
    auth: { authenticated }
  } = props;

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: detailProfile, isLoading, refetch, isFetching } = useGetApplicantsProfileQuery(id);
  useEffect(() => {
    refetch();
  }, []);

  // debugger;

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value: isLoading || isFetching
      }
    });
  }, [isLoading, isFetching]);

  const stateName = useMemo(
    () =>
      stateData?.find((item) =>
        item.state_id == detailProfile?.state ? detailProfile?.state : detailProfile?.company?.state
      )?.state_name ?? '',
    [detailProfile]
  );

  const cityName = useMemo(
    () =>
      cityData[2].data.find((city) =>
        city.city_id === detailProfile?.city ? detailProfile?.city : detailProfile?.company?.city
      )?.city_name ?? '',
    [detailProfile]
  );

  const companyCity = useMemo(
    () =>
      cityData[2].data.find((city) =>
        city.city_id == detailProfile?.company?.city ? detailProfile?.company?.city : ''
      )?.city_name ?? '',
    [detailProfile]
  );

  const companyState = useMemo(
    () =>
      stateData?.find((item) =>
        item.state_id == detailProfile?.company?.state ? detailProfile?.company?.state : ''
      )?.state_name ?? '',
    [detailProfile]
  );

  console.log(stateName, cityName, 'stateName, cityName');
  const userType = detailProfile?.user_type;

  return (
    <>
      <div className="header-second">
        <HeaderLogo />
      </div>
      <Container>
        <div className="content-inner-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <h2 className="title-2 m-0">{detailProfile?.name}</h2>
            </Grid>
            {authenticated?.id != id && (
              <Grid item xs={5}>
                <Box textAlign={'end'}>
                  <button
                    className="btn btn-outline-grey"
                    onClick={() => {
                      navigate(routerPath.messages.chatRoomPrivate.replace(':profile_id', id));
                    }}>
                    <img src={IconMessage} className="bottom" style={{ width: '15px' }} alt="" />{' '}
                    Message
                  </button>
                </Box>
              </Grid>
            )}
          </Grid>
          <h3 className="subtitle">{userType}</h3>
          <div className="profile-images">
            <img
              src={
                detailProfile?.profile_image?.split('?')[0] ||
                detailProfile?.company?.logo.split('?')[0] ||
                ProfileImage
              }
              alt=""
            />
          </div>

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 3 }}>
              <h3 className="subtitle">Personal Information</h3>
              <ListGroup name="Name: " desc={detailProfile?.name} />
              <ListGroup
                name="Age: "
                desc={detailProfile?.age ? detailProfile?.age : detailProfile?.company?.age}
              />
              <ListGroup
                name="Gender: "
                desc={
                  detailProfile?.gender ? detailProfile?.gender : detailProfile?.company?.gender
                }
              />
            </Box>
          )}

          {[RECRUITER].includes(userType) && (
            <Box sx={{ pt: 3 }}>
              <h3 className="subtitle">Company Contact Information</h3>
              <ListGroup
                name="First name: "
                desc={detailProfile?.company?.name?.split(' ')?.[0] || '-'}
              />
              <ListGroup
                name="Last name: "
                desc={detailProfile?.company?.name?.split(' ').splice(1).join(' ') || '-'}
              />
              <ListGroup name="Email: " desc={detailProfile?.company?.email || '-'} />
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 3 }}>
              <h3 className="subtitle">Location</h3>
              <ListGroup name="City: " desc={cityName} />
              <ListGroup name="State: " desc={stateName} />
              <ListGroup
                name="Zip Code: "
                desc={
                  detailProfile?.zipcode ? detailProfile?.zipcode : detailProfile?.company?.zipcode
                }
              />
              <ListGroup
                name="Travel Distance: "
                desc={
                  detailProfile?.travel_distance
                    ? detailProfile?.travel_distance
                    : detailProfile?.company?.travel_distance
                }
              />
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 3, pb: 3 }}>
              <h3 className="subtitle">Language</h3>
              {!!detailProfile?.language &&
                detailProfile?.language.map((e, k) => (
                  <ListGroup key={k} name="Primary Language: " desc={JSON.stringify(e)} />
                ))}
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ py: 3 }}>
              <h3 className="subtitle">Project Pictures</h3>
              <div className="project-photo">
                {detailProfile?.company &&
                  detailProfile?.company?.images?.map((e, idx) => (
                    <div className="photo-item" key={idx}>
                      <div className="images-box">
                        <img src={e.image} alt="" />
                      </div>
                    </div>
                  ))}
              </div>
            </Box>
          )}

          {[JOB_SEEKER, RECRUITER].includes(userType) && (
            <Box sx={{ py: 3 }}>
              <h3 className="subtitle">About Me</h3>
              <p>
                {detailProfile?.about_me
                  ? formatDescription(detailProfile?.about_me)
                  : formatDescription(detailProfile?.company?.about)}
              </p>
            </Box>
          )}

          {[RECRUITER, SUB_CONTRACTOR, GENERAL_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(
            userType
          ) && (
            <Box sx={{ pt: 3 }}>
              <h3 className="subtitle">Company Information</h3>

              <ListGroup name="Company name: " desc={detailProfile?.company?.name || '-'} />
              <ListGroup name="Website: " desc={detailProfile?.company?.website || '-'} />
              <ListGroup name="ZIP Code: " desc={detailProfile?.company?.zipcode || '-'} />
              <ListGroup name="City: " desc={companyCity || '-'} />
              <ListGroup name="State: " desc={companyState || '-'} />
              {(userType === 'Sub Contractor' || userType === '2nd Tier Subcontractor') && (
                <ListGroup
                  name="Travel distance: "
                  desc={detailProfile?.company?.travel_distance || '-'}
                />
              )}
            </Box>
          )}

          {[SUB_CONTRACTOR, GENERAL_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ pt: 3 }}>
              <h3 className="subtitle">Company Contact Information</h3>
              <ListGroup name="Phone number: " desc={detailProfile?.company?.phone_number || '-'} />
              <ListGroup name="Fax number: " desc={detailProfile?.company?.fax || '-'} />
              <ListGroup name="Email address: " desc={detailProfile?.company?.email || '-'} />
            </Box>
          )}

          {[SUB_CONTRACTOR, GENERAL_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ py: 3 }}>
              <h3 className="subtitle">Project Pictures</h3>
              <div className="project-photo">
                {detailProfile?.company && detailProfile?.company?.images?.length
                  ? detailProfile?.company?.images?.map((e, idx) => (
                      <div className="photo-item" key={idx}>
                        <div className="images-box">
                          <img src={e.image} alt="" />
                        </div>
                      </div>
                    ))
                  : '-'}
              </div>
            </Box>
          )}

          {[SUB_CONTRACTOR, GENERAL_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ py: 3 }}>
              <h3 className="subtitle">About the Company</h3>
              <p>{formatDescription(detailProfile?.company?.about) || '-'}</p>
            </Box>
          )}

          {[JOB_SEEKER, SUB_CONTRACTOR, GENERAL_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(
            userType
          ) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Industry</h3>
              {detailProfile?.industry &&
                detailProfile?.industry
                  .filter((e) => e.industry)
                  .map(({ industry, id }, idx) => (
                    <span className="tag-label" key={id}>
                      {industry}
                    </span>
                  ))}
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Profession</h3>
              {detailProfile?.user_profession && detailProfile?.user_profession?.length
                ? detailProfile?.user_profession.map(({ profession, id }, idx) => (
                    <span className="tag-label" key={id}>
                      {profession}
                    </span>
                  ))
                : '-'}
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Trade Affiliation</h3>

              {detailProfile?.user_trade_affiliation &&
              detailProfile?.user_trade_affiliation?.length
                ? detailProfile?.user_trade_affiliation.map(({ trade_affiliation, id }, idx) => (
                    <span className="tag-label" key={id}>
                      {trade_affiliation}
                    </span>
                  ))
                : '-'}
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Skill Set</h3>

              {detailProfile?.user_skill_set && detailProfile?.user_skill_set?.length
                ? detailProfile?.user_skill_set.map(({ skill_set, id }, idx) => (
                    <span className="tag-label" key={id}>
                      {skill_set}
                    </span>
                  ))
                : '-'}
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Education</h3>

              {detailProfile?.user_education && detailProfile?.user_education?.length
                ? detailProfile?.user_education.map(({ education, id }, idx) => (
                    <span className="tag-label" key={id}>
                      {education}
                    </span>
                  ))
                : '-'}
            </Box>
          )}

          {[JOB_SEEKER].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Experience</h3>
              <span className="tag-label">{detailProfile?.experience || '-'}</span>
            </Box>
          )}

          {[SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Construction Trades</h3>

              {detailProfile?.construction_trade && detailProfile?.construction_trade?.length
                ? detailProfile?.construction_trade.map(({ trade, id }, idx) => (
                    <span className="tag-label" key={id}>
                      {trade}
                    </span>
                  ))
                : '-'}
            </Box>
          )}

          {[SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Labor Affiliation</h3>

              <span className="tag-label">{detailProfile?.labor_affiliation || '-'}</span>
            </Box>
          )}

          {[SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Construction Bond</h3>
              <span className="tag-label">
                {detailProfile?.construction_bond ? 'True' : 'False' || '-'}
              </span>
            </Box>
          )}

          {[SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Minority - Owned Certified</h3>
              <span className="tag-label">
                {detailProfile?.minority_owned_certificate ? 'True' : 'False' || '-'}
              </span>
            </Box>
          )}

          {[JOB_SEEKER, SUB_CONTRACTOR, SECOND_TIER_CONTRACTOR].includes(userType) && (
            <Box sx={{ pt: 1 }}>
              <h3 className="subtitle">Trade Licence / Certificates</h3>

              {detailProfile?.user_trade_license && detailProfile?.user_trade_license?.length
                ? detailProfile?.user_trade_license.map(({ trade_license, id }, idx) => (
                    <span className="tag-label" key={id}>
                      {trade_license}
                    </span>
                  ))
                : '-'}
            </Box>
          )}

          <Box sx={{ pt: 2 }}>
            <button className="btn btn-primary mr-1" onClick={() => navigate(-1)}>
              Close
            </button>
          </Box>
        </div>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};
export default connect(mapStateToProps)(ViewProfile);
