import { useEffect, useState } from 'react';
import SidebarMenu from 'components/SidebarMenu';
import { connect, useDispatch, useSelector } from 'react-redux';
import { actionToProps as authAction } from 'store/reducers/auth/auth.action';
import { actionToProps as newsAction } from 'store/reducers/news/news.action';
import { actionToProps as jobsAction } from 'store/reducers/news/actions/actionGetAllJobs';
import { routerPath } from 'router/Routerlist';
import IconPlus from 'assets/plus.svg';
import IconHelmet from 'assets/helmet.svg';
import FooterLinkDashboard from 'components/FooterLink/Dashboard';
import DashboardHeaderJobVacancy from 'components/DashboardHeader/JobVacancy';
import JobListCard from 'components/JobListCard';
import Pagination from '@mui/material/Pagination';
import { Dialog, DialogContent, Grid } from '@mui/material';
import {
  useDeleteFavoriteJobMutation,
  useGetMyFavoriteJobListQuery,
  usePostFavouriteJobMutation,
  usePostQuickApplyJobMutation,
  usePostSubmitReportMutation,
  useViewAllJobsMutation
} from 'store/slices/apiSlice';
import { CHANGE_ATTRIBUTE } from 'store/reducers/news/news.constant';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as cx from 'classnames';
import { errorValidationHandler } from 'utils/errorHandler';
import moment from 'moment';

const defaultFilter = {
  limit: 8,
  search: '',
  city: '',
  state: '',
  offset: 0
};

const MyJob = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page')) || 1;

  const {
    history,
    actionGetAllJobs,
    news: { jobs }
  } = props;
  const userData = useSelector((state) => state.auth.authenticated);
  const [filter, setFilter] = useState(defaultFilter);

  const [postSubmitReport] = usePostSubmitReportMutation();

  const [
    viewAllJobs,
    {
      data: allJobResult,
      isLoading,
      isError: isErrorGetAllJobs,
      error: errorGetAllJob,
      isFetching: isFetchingAllJob,
      isSuccess: isSuccessGetAllJob
    }
  ] = useViewAllJobsMutation();

  const { data: myFavoriteJob, refetch: refetchMyFavoriteJob } = useGetMyFavoriteJobListQuery();
  const [
    deleteFavoriteJob,
    { isLoading: isLoadingDeleteFavorite, isSuccess: isSuccessDeleteFavoriteJob }
  ] = useDeleteFavoriteJobMutation();

  const [
    postQuickApplyJob,
    {
      isLoading: loadingApplyJob,
      isError: isErrorApplyJob,
      error: errorApplyJob,
      isSuccess: isSuccessQuickApply
    }
  ] = usePostQuickApplyJobMutation();

  const [
    postFavouriteJob,
    {
      isLoading: loadingFavouriteJob,
      isError: isErrorFavouriteJob,
      error: errorFavouriteJob,
      isSuccess: isSuccessFavoriteJob
    }
  ] = usePostFavouriteJobMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccessFavoriteJob || isSuccessDeleteFavoriteJob) {
      refetchMyFavoriteJob();
    }
  }, [isSuccessFavoriteJob, isSuccessDeleteFavoriteJob]);

  useEffect(() => {
    if (isSuccessQuickApply) {
      const savedFilter = localStorage.getItem('workium_jobFilter');
      if (savedFilter) {
        const parsedFilter = JSON.parse(savedFilter);
        viewAllJobs({
          ...filter,
          ...parsedFilter,
          is_paid: true
        });
      } else {
        viewAllJobs({
          ...filter,
          is_paid: true
        });
      }
    }
  }, [isSuccessQuickApply]);

  useEffect(() => {
    viewAllJobs({ ...filter, is_paid: true });
    refetchMyFavoriteJob();
  }, []);

  // const reftchJob = () => {
  //   refetchMyFavoriteJob();
  // };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: CHANGE_ATTRIBUTE,
      payload: {
        key: 'isPageLoading',
        value:
          isLoading ||
          loadingApplyJob ||
          loadingFavouriteJob ||
          isLoadingDeleteFavorite ||
          isFetchingAllJob
      }
    });
  }, [isLoading, loadingApplyJob, loadingFavouriteJob, isLoadingDeleteFavorite, isFetchingAllJob]);

  useEffect(() => {
    if (isErrorApplyJob || isErrorFavouriteJob || isErrorGetAllJobs) {
      const message = errorValidationHandler(
        errorApplyJob?.data || errorFavouriteJob?.data || errorGetAllJob?.data
      );
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'error'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: message?.alert
        }
      });
    }
  }, [isErrorApplyJob, isErrorFavouriteJob, isErrorGetAllJobs]);

  const [openDialog, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  useEffect(() => {
    if (isSuccessQuickApply) {
      const message = 'you have successfully applied for this job';

      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: [message]
        }
      });
    }
  }, [isSuccessQuickApply]);

  useEffect(() => {
    if (isSuccessFavoriteJob) {
      const message = 'Added to your favorite job!';

      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'alertSeverity',
          value: 'success'
        }
      });
      dispatch({
        type: CHANGE_ATTRIBUTE,
        payload: {
          key: 'errors',
          value: [message]
        }
      });
      refetchMyFavoriteJob();
    }
  }, [isSuccessFavoriteJob]);

  const onQuickApply = async (ele) => {
    if (!ele?.user) {
      return (window.location.href = ele?.job_url);
    }

    if (ele?.user?.job_applications_notification) {
      const data = {
        email: ele?.user?.email,
        message: `Hi there,\n\n\nI trust you're doing well. I'm writing to express my keen interest in the ${ele?.title} position listed on Workium. \n\nI invite you to review my profile and message me via Workium with your preferred method of contact if you believe that I could be a good fit for your opening. \n\nI'm excited about the opportunity to discuss how my skills align with your requirements. \n\nThank you for considering my application.\n\n\nBest regards,\n\nWorkium Team`,
        subject: `Application for ${ele?.title} Position`
      };
      await postSubmitReport(data);
    }

    const payload = {
      job_id: ele.id,
      user_id: ele.user?.id,
      city: ele?.city,
      description: ele?.description,
      distance: 10,
      state: ele?.state,
      title: ele?.title
    };

    postQuickApplyJob(payload);
  };

  const onAddFavourite = (ele) => {
    const payload = {
      job: ele.id
    };

    postFavouriteJob(payload);
  };

  const onDeleteFavorite = async (ids) => {
    const deleteTheJob = ids.map((e) => {
      return deleteFavoriteJob(e);
    });

    await Promise.all([...deleteTheJob]);
  };

  const handlePageChange = (event, newPage) => {
    setSearchParams({ page: newPage });
    viewAllJobs({
      ...filter,
      offset: (newPage - 1) * filter.limit
    });
  };

  useEffect(() => {
    viewAllJobs({
      ...filter,
      offset: (page - 1) * filter.limit
    });
  }, [page, filter, viewAllJobs]);

  useEffect(() => {
    const savedFilter = localStorage.getItem('workium_jobFilter');
    if (savedFilter) {
      const parsedFilter = JSON.parse(savedFilter);
      viewAllJobs({
        ...filter,
        ...parsedFilter,
        is_paid: true
      });
    } else {
      viewAllJobs({
        ...filter,
        is_paid: true
      });
    }
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="user-dashboard">
          <SidebarMenu />
          <div className="content-dashboard">
            <DashboardHeaderJobVacancy
              title="Job Posts"
              onSearchJobResult={(f) => {
                viewAllJobs({
                  ...filter,
                  ...f,
                  is_paid: true
                });
              }}
            />

            <div className="content-inner" style={{ marginTop: 30 }}>
              {!!allJobResult && allJobResult?.results?.length === 0 && (
                <div className="empty-project">
                  <img src={IconHelmet} />
                  <p className="mt-2">
                    You have not posted the Job yet. <br />
                    Let's start right now!
                  </p>
                  <button
                    onClick={() => navigate(routerPath.jobs.createjob)}
                    className="btn btn-success mt-2">
                    <img src={IconPlus} className="sub mr-1" />
                    Create Job
                  </button>
                </div>
              )}
              {!!allJobResult &&
                allJobResult?.results?.map((ele, key) => {
                  return (
                    <JobListCard
                      className={cx({
                        featured: ele.is_featured,
                        urgent: ele.is_urgent
                      })}
                      isFavorite={myFavoriteJob?.results?.map((e) => e.job).includes(ele.id)}
                      image={ele.images.map((e) => e.image?.split('?')[0])?.[0]}
                      // image={ele?.job_category?.image?.split('?')?.[0]}
                      title={ele.title}
                      quickApplyTitle={
                        ele?.user
                          ? ele.applicants?.find((x) => x.user.id == userData?.id)?.user?.id
                            ? 'Applied'
                            : 'Quick Apply'
                          : 'Apply'
                      }
                      user={ele?.user}
                      id={ele.id}
                      posted={moment(ele.posted).fromNow()}
                      location={`${ele?.state}, ${ele?.city}`}
                      stateId={ele?.state}
                      cityId={ele?.city}
                      desc={ele.description}
                      onAddFavourite={() => {
                        if (myFavoriteJob?.results?.map((e) => e.job).includes(ele.id)) {
                          const jobDeletedItems = myFavoriteJob.results
                            .map((e) => {
                              if (e.job === ele.id) {
                                return e;
                              }
                            })
                            .filter((f) => f);
                          onDeleteFavorite(jobDeletedItems.map((f) => f.id));
                        } else {
                          onAddFavourite(ele);
                        }
                      }}
                      onClick={() => {
                        navigate(routerPath.jobs.detail.replace(':job_id', ele.id), {
                          state: {
                            isFavorite: myFavoriteJob?.results?.map((e) => e?.job).includes(ele.id)
                          }
                        });
                      }}
                      onQuickApply={() => {
                        onQuickApply(ele);
                      }}
                    />
                  );
                })}
              <Grid container justify="flex-end" className="mt-4">
                <Pagination
                  count={Math.ceil(allJobResult?.count / filter.limit)}
                  color="success"
                  variant="outlined"
                  page={page}
                  onChange={handlePageChange}
                />
              </Grid>
            </div>

            <FooterLinkDashboard />
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => {
          setDialogOpen(!openDialog);
        }}>
        <DialogContent>{dialogMessage}</DialogContent>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news
  };
};
const mapDispatchToProps = { ...authAction, ...newsAction, ...jobsAction };
export default connect(mapStateToProps, mapDispatchToProps)(MyJob);
